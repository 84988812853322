import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridComparatorFn, GridPagination, GridSlotsComponentsProps, gridPageCountSelector, useGridApiContext, useGridSelector} from '@mui/x-data-grid';
import { useState} from "react";
import { TablePaginationProps } from '@mui/material/TablePagination';
import MuiPagination from '@mui/material/Pagination';
import Chip from '@mui/material/Chip';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
import { usePutStaffRequestMutation } from '../services/staffAPI';
import { RequestStatus, RequestType } from '../models/StaffRequest';
import { StaffRequest } from '../models/StaffRequest';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DialogConfirm from './DialogConfirm';
import SnackbarAlert from './SnackbarAlert';
import EjectIcon from '@mui/icons-material/Eject';
import { convertDuration } from '../utils/stringFormatter';
import { SnackbarConfig } from '../models/SnackbarConfig';
import { countryFlagIconSelector } from '../utils/stringFormatter';
import CircularProgress from '@mui/material/CircularProgress';
 

type FooterStatus = 'connected' | 'disconnected';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    status: FooterStatus;
  }
}

export function CustomFooterStatusComponent(
    props: NonNullable<GridSlotsComponentsProps['footer']>,
) {

  function CustomPagination(props: any) {

    function Pagination({
      page,
      onPageChange,
      className,
    }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
      const apiRef = useGridApiContext();
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        <MuiPagination
          color="primary"
          className={className}
          count={pageCount}
          page={page + 1}
          onChange={(event, newPage) => {
            onPageChange(event as any, newPage - 1);
          }}
        />
      );
    }

    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }
  
  return (
    <>
      <Box sx={{p: 1, display: 'flex', justifyContent: 'space-between'}}>
        <CustomPagination />
      </Box>
    </>
  );
}

export default function DatatableRequests({ staffRequests, setStaffRequests }: {staffRequests: StaffRequest[], setStaffRequests: React.Dispatch<React.SetStateAction<StaffRequest[]>>}) {

  const [putStaffRequest] = usePutStaffRequestMutation();
  const [snackbarConfig, setSnackbarConfig] = useState({} as SnackbarConfig);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [confirmStaffRequest, setConfirmStaffRequest] = useState<StaffRequest>({} as StaffRequest);

  const handleDialogConfirmOpen = (staffRequest: StaffRequest) => {
    setConfirmStaffRequest(staffRequest);
    setOpenDialogConfirm(true);
  };

  const handlePutStaffRequest = async (id: string, status: RequestStatus) => {
    try {
      const payload = await putStaffRequest({ id: id, status: status }).unwrap();
  
      setStaffRequests(prevStaffRequests =>
        prevStaffRequests.map(sr =>
          sr.id === id ? { ...sr, status: status, loading: false } : sr
        )
      );
      setSnackbarConfig({ message: 'Action successful', severity: 'success', open: true });
    } catch (e) {
      setStaffRequests(prevStaffRequests =>
        prevStaffRequests.map(sr =>
          sr.id === id ? { ...sr, loading: false } : sr
        )
      );
      setSnackbarConfig({ message: 'Action failed', severity: 'error', open: true });
      console.error(e);
    }
  };

  const pendingRequestComparator: GridComparatorFn<string> = (v1, v2) => {
    if (v1 === 'PENDING' && v2 !== 'PENDING') return -1 
    else if (v1 === 'REJECTED' && v2 !== 'PENDING') return -1 
    else return 0;
  };

  function AcceptActionButton(params: any) {
  
    const handleAcceptClick = async () => {
      try {
        // set loading to true for this staff request
        setStaffRequests(prevStaffRequests =>
          prevStaffRequests.map(sr =>
            sr.id === params.row.id ? { ...sr, loading: true } : sr
          )
        );
        await handlePutStaffRequest(params.row.id, RequestStatus.ACCEPTED);
      } catch (error) {
        console.error("Error accepting request:", error);
      }
    };
  
    if (params.row.status === 'ACCEPTED') {
      return (
        <Chip variant="outlined" size="small" label={new Date(params.row.last_modification_date).toLocaleDateString()} />
      );
    } else if (params.row.status === 'PENDING') {
      return params.row.loading ? (
        <CircularProgress size={24} />
      ) : (
        <GridActionsCellItem
          className="accept-btn"
          icon={<TaskAltIcon />}
          color="success"
          label="Accept"
          onClick={handleAcceptClick}
        />
      );
    } else return null;
  }

  function RejectActionButton(params: any) {
    if (params.row.status === 'REJECTED') {
      return (
        <Chip variant="outlined" size="small" label={ new Date(params.row.last_modification_date).toLocaleDateString() } />
      )
    } else if (params.row.status === 'PENDING') {
      return (
        <GridActionsCellItem icon={<CloseIcon />} color="error" label="Reject" onClick={() => handleDialogConfirmOpen({...params.row, status: RequestStatus.REJECTED})} />
      )
    } else return null;
  }

  const columns: GridColDef[] = [


    {field: 'status', headerName: 'Status', width: 120,
      renderCell: (params) => {
        return (
          <Chip size="small" label={params.value} color={params.value === 'ACCEPTED' ? 'success' : params.value === 'REJECTED' ? 'error' : 'warning'} />
        )
      },
      sortComparator: pendingRequestComparator
    },
    {field: 'requesting_user', headerName: 'Requesting User', valueGetter: (params) => params.row.requesting_user.name, width: 135,
      renderCell: (params) => {
        return (<span>{params.value}</span>)
      }
    },
    {field: 'target_user', headerName: 'Target User', valueGetter: (params) => params.row.target_user.name, width: 260,
      renderCell: (params) => {
        return (
          <>
            <strong style={{ marginRight: 10 }}>{params.value}</strong>
            { params.row.target_user.country ? 
            <Chip size="small" icon={countryFlagIconSelector(params.row.target_user.country)} color="info" label={params.row.target_user.team} /> 
            : null
          }
          </>
        )
      }
    },
    {field: 'reason', headerName: 'Reason', width: 180},
    {field: 'duration', headerName: 'Duration', type: 'number', width: 130,
      renderCell: (params) => {
        return (
          <Chip size="small" color="secondary" style={{ borderRadius: "2px" }} label={convertDuration(params.row.duration)} icon={<TimelapseIcon />} />
        )
      }
    },
    {field: 'customer', headerName: 'Customer', type: 'string', valueGetter: (params) => params.row.request.name ? params.row.request.name : params.row.request.id, width: 200,
      renderCell: (params) => {
        return (
          <>
          {params.row.request.gsid ? 
          <a href={`https://agicap.eu.gainsightcloud.com/v1/ui/customersuccess360?cid=${params.row.request.gsid}`}>{params.value}</a>
          : params.row.request.user_id ?
          <a href={`https://app.agicap.com/backoffice/utilisateur/get/${params.row.request.user_id}`}>{params.row.request.user_id}</a>
          : params.row.request.consolidation_id ?
          <a href={`https://app.agicap.com/backoffice/consolidation/${params.row.request.consolidation_id}`}>{params.row.request.consolidation_id}</a>
          : <a href={`https://app.agicap.com/backoffice/entreprise/get/${params.row.request.id}/company`}>{params.value}</a>
          }
          </>
        )
      }
    },
    {field: 'customer_stage', headerName: 'Stage', type: 'string', valueGetter: (params) => params.row.request.stage},
    {field: 'customer_country', headerName: 'Country', type: 'string',
      renderCell: (params) => {
        return (
          <>
          { params.row.request.country ?
          <Chip size="small" icon={countryFlagIconSelector(params.row.request.country)} label={params.row.request.country} />
          : null }
          </>
        )
      }
    },
    {field: 'customer_type', headerName: 'Type', type: 'string', valueGetter: (params) => params.row.request.type},
    {field: 'nb_requests', headerName: 'Last 3 days Requests', type: 'number',
      renderCell: (params) => {
        return (
          <Chip size="small" label={params.row.requesting_user.nb_requests} color="info" deleteIcon={<EjectIcon />} onDelete={() => {}} />
        )
      }
    },
    {field: 'request_date', headerName: 'Request Date', type: 'string', valueGetter: (params) => new Date(params.row.request_date).toLocaleString(), width: 160},
    {field: 'actions', headerName: 'Action', type: 'actions', width: 130, 
      getActions: (params: any) => [
        <AcceptActionButton {...params} />,
        <RejectActionButton {...params} />
     ],
    },

  ];

    const [status] = useState<FooterStatus>('connected');

    const filteredStaffRequests = staffRequests.filter(
      sr => !(sr.status === 'ACCEPTED' && sr.request_type === RequestType.AUTO)
     );

    return (
      <div style={{height: 720, width: '100%'}}>
        <DataGrid
          rows={filteredStaffRequests}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {page: 0, pageSize: 15},
            },
            sorting: {
              sortModel: [{ field: 'status', sort: 'asc' }],
            }
          }}
          pageSizeOptions={[5, 10, 15, 30]}
          slots={{
            footer: CustomFooterStatusComponent,
          }}
          slotProps={{
            footer: {status},
          }}
        />
        <DialogConfirm message="Are you sure you want to reject this request?" staffRequest={confirmStaffRequest}  open={openDialogConfirm} handleCancel={() => setOpenDialogConfirm(false)} handleConfirm={() => { handlePutStaffRequest(confirmStaffRequest.id, RequestStatus.REJECTED); setOpenDialogConfirm(false)}} />
        <SnackbarAlert message={snackbarConfig.message} severity={snackbarConfig.severity} open={snackbarConfig.open} handleClose={() => setSnackbarConfig({...snackbarConfig, open: false})} />

      </div>
    );
}
