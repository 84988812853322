import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
type HeaderProps = {
    title?: string
    isSignedIn: boolean
    handleLogin: () => Promise<void>
    handleLogout: () => Promise<void>
}

export default function Header({title, isSignedIn, handleLogin, handleLogout}: HeaderProps) {

  const loginButton = isSignedIn ? 
    <Button sx={{ color: '#fff' }} onClick={handleLogout} endIcon={<LogoutIcon />}> Logout </Button> :
    <Button color="inherit" onClick={handleLogin}> Sign In</Button>;

  return (
    <Toolbar
      sx={{
          pr: '24px', // keep right padding when drawer closed
          backgroundColor: '#7b65c9',
      }}
    >
        
      <Typography
        component="h1"
        variant="h6"
        color="white"
        noWrap
        sx={{flexGrow: 1}}
      >
          {title}
      </Typography>

      {loginButton}

    </Toolbar>
  )
}
  