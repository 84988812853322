export enum RequestStatus {
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
}

export enum RequestUserType {
    USER = "USER",
    GROUP = "GROUP",
}

export type RequestUser = {
    type: RequestUserType,
    email: string,
    name: string,
    nb_requests: number,
    team: string,
}

export enum RequestType {
    CLASSIC = "CLASSIC",
    TRANSFER = "TRANSFER",
    AUTO = "AUTO"
}

export type RequestDetails = {
    type: string,
    id: string,
    gsid: string,
    country: string,
    stage: string,
}

export type StaffRequest = {
    id: string,
    reason: string,
    status: RequestStatus,
    requesting_user: RequestUser,
    target_user: RequestUser,
    request_date: string,
    request: RequestDetails,
    duration: number,
    request_type: RequestType,
    nb_requests: number,
    granted_by: string,
    loading?: boolean,
}

export type FilterStaffRequest = {
    status: RequestStatus,
    country: string,
    since: number | null,
}